import React from 'react'
import Appbar from '../Appbar/Appbar'
import Countdown from './Countdown/Countdown'
import "./Section1.css"
function Section1() {
    return (
        <div className='section1'>
          
          
            <div>
            <Countdown 	timeTillDate="02 09 2022, 19:30 pm" 
		timeFormat="MM DD YYYY, h:mm a"/>


            </div>
            
        </div>
    )
}

export default Section1
