import './App.css';
import Appbar from './component/Appbar/Appbar';
import Section1 from './component/Section1/Section1';
// import Section2 from './component/Section2/Section2';
// import Section4 from './component/Section4/Section4';
// import Section5 from './component/Section5/Section5';
// import Section6 from './component/Section6/Section6';
import { Suspense,lazy } from 'react';



const Section2=lazy(()=> 
new Promise((resolve, reject) =>
setTimeout(() => resolve(import("./component/Section2/Section2")), 1000)
)


)

const Section4=lazy(()=> new Promise((resolve, reject) =>
setTimeout(() => resolve(import("./component/Section4/Section4")), 1000)
))

const Section5=lazy(()=> new Promise((resolve, reject) =>
setTimeout(() => resolve(import("./component/Section5/Section5")), 1000)
))

const Section6=lazy(()=> new Promise((resolve, reject) =>
setTimeout(() => resolve(import("./component/Section6/Section6")), 1000)
))
function App() {
  return (
    <div className="App">

<Appbar/>
      <Section1/>
      <Suspense fallback={<div/>}>
      <Section2/>
      </Suspense>
   
      <Suspense fallback={<div/>}>
      <Section4/>
      </Suspense>
      <Suspense fallback={<div/>}>
      <Section5/>
      </Suspense>
      <Suspense fallback={<div/>}>
      <Section6/>
      </Suspense>
    </div>
  );
}

export default App;
